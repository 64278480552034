import React, { useContext } from 'react'

import { MainContext } from "context/context"

import Link from 'components/link'
import Scenario from 'components/singleEnvironmentScenario'
import Button from 'components/button'

export default ({
    environment,
}) => {
    const {
        title,
        productTaxes,
        environmentFields: {
            intro: {
                title: introTitle,
                copy: introCopy,
            },
            scenarios,
        },
    } = environment

	const {
        options: {
            environmentsCtas,
        }
    } = useContext(MainContext)

    const product = productTaxes?.nodes?.[0]

    return (
        <div className='singleEnvironment'>
            <div className='singleEnvironment-hero'>
                <div className="container">
                    <h1 dangerouslySetInnerHTML={{ __html: title }} />
                </div>
            </div>
            <div className="container">
                <div className='singleEnvironment-breadcrumbs'>
                    <Link to={'/home'}>Home</Link>
                    <Link to={'/environments'}>Environments</Link>
                    { product &&
                        <Link to={`/environments${ product?.slug !== 'trainer' ? `?type=${product?.slug}` : '' }`}>{product?.name}</Link>
                    }
                    <div dangerouslySetInnerHTML={{ __html: title }} />
                </div>
                { ( introTitle || introCopy ) &&
                    <div className='singleEnvironment-intro'>
                        { introTitle &&
                            <div className='singleEnvironment-intro-title'>
                                <h3 dangerouslySetInnerHTML={{ __html: introTitle }} />
                            </div>
                        }
                        { introCopy &&
                            <div
                                className='singleEnvironment-intro-copy'
                                dangerouslySetInnerHTML={{ __html: introCopy }}
                            />
                        }
                    </div>
                }
            </div>
            { scenarios &&
                <div className='singleEnvironment-scenarios'>
                    <div className="container">
                        {scenarios?.map((scenario, index) => {
                            return (
                                <Scenario
                                    scenario={scenario}
                                    key={index}
                                />
                            )
                        })}
                    </div>
                </div>
            }
            { environmentsCtas &&
                <div className='singleEnvironment-ctas'>
                    <div className="container">
                        { environmentsCtas?.map((cta, index) => {
                            const {
                                title,
                                copy,
                                link,
                            } = cta
                            
                            return (
                                <div
                                    className='singleEnvironment-cta'
                                    key={index}
                                >
                                    <h3 dangerouslySetInnerHTML={{ __html: title }} />
                                    <div
                                        className='copy'
                                        dangerouslySetInnerHTML={{ __html: copy }}
                                    />
                                    { link &&
                                        <Button
                                            url={link?.url}
                                            label={link?.title}
                                            inverted={index === 1}
                                        />
                                    }
                                </div>
                            )
                        })}
                    </div>
                </div>
            }
        </div>
    )
}
