import React from "react"
import { graphql } from "gatsby"

import SEO from "components/seo"
import SingleEnvironment from 'components/singleEnvironment'

export default ({ data: {
    siteFields: {
        environment,
    },
} }) => {
    const {
        title,
        excerpt: defaultExcerpt,
    } = environment

    return (
        <>
            <SEO title={title} description={defaultExcerpt} />
            <SingleEnvironment environment={environment} />
        </>
    )
}

export const query = graphql`
    query($databaseId: ID!) {
        siteFields {
            environment(id: $databaseId, idType: DATABASE_ID) {
                databaseId
                title
                date
                content(format: RENDERED)
                featuredImage {
                    altText
                    title(format: RENDERED)
                    mediaItemUrl
                    slug
                }
                productTaxes {
                    nodes {
                        name
                        slug
                    }
                }
                environmentFields {
                    intro {
                        title
                        copy
                    }
                    scenarios {
                        title
                        copy
                        image {
                            altText
                            title(format: RENDERED)
                            mediaItemUrl
                            slug
                        }
                        video {
                            mediaItemUrl
                        }
                    }
                }
            }
        }
    }
`